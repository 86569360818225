import React, { useState, useEffect } from 'react';

const defaultState = {
  english: true,
  french: false,
};

export const LangContext = React.createContext(defaultState);

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  switch (lang) {
    case 'fr':
      return 'fr';
    default:
      return 'en';
  }
};

export const LangProvider = ({ children }) => {
  const [isEnglish, setIsEnglish] = useState(true);

  useEffect(() => {
    const browserLang = getRedirectLanguage();
    if (browserLang === 'fr') {
      setIsEnglish(false);
    }
  }, []);

  return (
    <LangContext.Provider
      value={{
        isEnglish,
        setIsEnglish,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
