import React, { Fragment } from 'react';
import { LangProvider } from '../context/LangContext';

const RootElement = ({ children }) => {
  return (
    <Fragment>
      <script
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-4F1Q9M9RHV'
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', 'G-4F1Q9M9RHV', { send_page_view: false })`,
        }}
      ></script>
      <LangProvider>{children}</LangProvider>
    </Fragment>
  );
};

export default RootElement;
